<template>
    
</template>

<script>
export default{
    name: 'TotalCalc',
}
</script>

<style scoped>

</style>